<template>
  <GamesList title="Games" :show-filters="true" />
</template>

<script>
import GamesList from "@/components/GamesList.vue";

export default {
  components: { GamesList },
  metaInfo: {
    title: "All Games | Lucky Won",
  },
};
</script>
